// extracted by mini-css-extract-plugin
export var aboutContainer = "index-module--aboutContainer--8e3f5";
export var aboutImage = "index-module--aboutImage--ec7e7";
export var aboutImageContainer = "index-module--aboutImageContainer--783a8";
export var blurbTitle = "index-module--blurbTitle--8b6b9";
export var descriptionContainer = "index-module--descriptionContainer--70173";
export var homeContainer = "index-module--homeContainer--14327";
export var homeOuterContainer = "index-module--homeOuterContainer--424d8";
export var introContainer = "index-module--introContainer--179b7";
export var mainHeader = "index-module--mainHeader--f74b7";
export var outerContainer = "index-module--outerContainer--204d5";
export var pageHeader = "index-module--pageHeader--d6753";
export var profilePic = "index-module--profilePic--8caa0";