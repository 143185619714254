import * as React from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import * as styles from "./index.module.css";
import { StaticImage } from "gatsby-plugin-image";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  // const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Handmade By Li" />
      <div className={styles.homeOuterContainer}>
          <h1 className={styles.blurbTitle}>handmade by li</h1>
        <div className={styles.homeContainer}>
          <div className={styles.introContainer}>
              <div>
                  <StaticImage
                      className={styles.profilePic}
                      formats={["auto", "webp", "avif"]}
                      src="../images/IMG_7553.jpg"
                      alt="Sophia happily holding a bundle of lavender in front of a lavender farm"
                  />
              </div>
              <div className={styles.descriptionContainer}>
              <p>Hi! I'm Sophia Li. </p>
                  <p>This is me in the photo, very excited about all the fresh lavender I just harvested at Helvetia Lavender Farms in Oregon.
                    When I got home, I had no idea what to do with all this lavender.
                      I gifted half to my neighbor and Googled what to do with the rest.
                      This is how I quickly fell down a rabbit hole of learning about hand made soaps and candles.</p>

                      <p>I'm just getting started, but I'd love to invite you along my crafting journey!
                          If you're interested in email updates on things like my learnings, tips & tricks, or soap & candle sample sign ups,
                          please join my Beta Testing Club by <a
                              href="https://forms.gle/jRAwX2UuvQFVqtMF6"
                              target="_blank"
                              rel="noopener noreferrer"
                          >filling out this Google Form</a> with your name and email.
                  </p>
                  <p>You can also follow along on  <a
                      href="https://instagram.com/handmadebyli.co"
                      target="_blank"
                      rel="noopener noreferrer"
                  >Instagram</a>.</p>
                  <p>Thanks for stopping by! </p>
              </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          path
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
